<template>
  <!-- 登录页 / -->
  <div class="login-bg">
    <div class="login-bg__image">
      <img src="@/assets/login/bg.webp" />
    </div>
    <!-- 登录表单卡片 -->
    <div class="login-bg__card">
      <div class="font24 N2" style="margin: 56px 0 24px">登 录</div>
      <div class="swing-in-top-fwd">
        <div class="avatar" v-loading="true">
          <div v-if="!user.avatar" class="name">{{ user.name ? user.name.slice(-2) : '' }}</div>
          <img v-else :src="user.avatar" :alt="user.name" />
        </div>
        <div class="N4 font14">您已登录用户中心, 正在自动登录...</div>
      </div>
      <el-button type="text" class="link" @click="$router.replace('/')"> <i class="el-icon-arrow-left" /> 返回登录页 </el-button>
    </div>
  </div>
</template>

<script>  
export default {
  data () {
    return {
      user: {},
    };
  },
  // 已有 TOKEN 时自动请求登录
  async mounted () {
    try {
      if (this.$route.query?.token) this.$cookie.set('TOKEN', this.$route.query.token) // 如传入TOKEN, 则用作登录
      let token = this.$cookie.get('TOKEN') // 条件1: 已缓存用户TOKEN   
      const { app_name, redirect_url } = this.$route.query // 条件2: 携带目标应用系统标识 
      if (!token) return this.$router.replace('/')
      if (!app_name || !redirect_url) return this.$router.replace('/dashboard')
      this.user = JSON.parse(localStorage.user) // user仅用于页面渲染
      setTimeout(async () => {
        this.ssoLogin(app_name, redirect_url, token) // 等页面渲染完成, 防止卡在白屏客户体验差
      }, 500);
    } catch (err) {
      this.$router.replace('/')
    }
  },

  methods: {
    // 登录到子系统, 并跳转至回调地址
    async ssoLogin (appName, redirectUrl, token) {
      const [, err] = await this.$post('ssoLogin', { appName }) // 请求单点登录
      if (err) return this.$msg(err)
      window.location.href = `${redirectUrl}?code=${localStorage.HOST_CODE}&token=${token}`
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/login-page.scss';
.loading-text {
  transition: all 0.3s;
}
.avatar {
  position: relative;
  border-radius: 6px;
  font-size: 40px;
  text-align: center;
  color: #dcdee0;
  background-color: #6e6f70;
  margin: 128px auto 24px;
  min-width: 104px;
  max-width: 104px;
  height: 104px;
}
.avatar .name {
  line-height: 104px;
}
.avatar img {
  width: 104px;
  height: 104px;
  object-fit: cover;
}
.link {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 490px;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-28 17:49:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.swing-in-top-fwd {
  -webkit-animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s both;
  animation: swing-in-top-fwd 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s both;
}
/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}
</style>


